@media only screen and (max-width: 740px) {

  h1, h2{
    font-size: 70px;
  }


}
@media only screen and (max-width: 992px) {
  .sidebar-wrapper{
    display: none;
  }
  .outer-menu .menu > ul > li > a {
    font-size: 70px;

  }
  .flex-item {
    flex-basis: 100%;

    .project-hover{
      width: calc(100% - 30px);
      padding: 10px 15px;

      h5{
        line-height: 20px;
        font-size: 20px;
        font-family: $main-font;
        font-weight: 900;
      }
      p{
        color: rgba(0, 0, 0, .5);
      }
    }
    &:hover .project-hover{
      z-index: 100;
      height: 100%;
    }
  }

  .contact-form{

    width: calc(100% - 40px);
    margin:  0 auto;
    padding: 5px 20px;
    border-radius: 20px;
    background-color: #E8E8E8;


  }
}
@media only screen and (max-width: 576px) {

  .flex-item {
    .project-hover {

      h5 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 20px;
      }
      p {
        font-size: 12px;
      }
    }
  }
  .services-item{
    width: 100%;
  }
  .outer-menu .menu > ul > li > a {
    font-size: 40px;

  }
  h1, h2{
    font-size: 60px;
  }


  .contact-flex{
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .social-list {
    margin-top: 40px;
  }
  .form-header{
    font-size: 40px;
  }
  .flex-check{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 40px;
  }
  .contact-form{

    width: calc(100% - 40px);
    margin:  0 auto;
    padding: 5px 20px;
    border-radius: 20px;
    background-color: #E8E8E8;


  }
}