$main-font: "Raleway", Helvetica, sans-serif;
$sub-font: "Roboto", Helvetica, sans-serif;
$main-gradient:linear-gradient(178deg, #FF096C 0%, #FD7329 100%);




body {
  font-family: $sub-font;
  font-weight: 400;
  background-color: #F3F3F3;

}

h1, h2{

  font-family: $main-font;
  font-weight: 900;
  text-transform: uppercase;
  font-size: 100px;
}
.text-gradient{
  background: $main-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
img{
  display: block;
  width: 100%;
  height: auto;
}
.section{
  margin-bottom: 200px;
}
.h-100 {
  height: 100%;
}
.mb-0{
  margin-bottom: 0;
}
.mb-4{
  margin-bottom: 50px;
}
.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.pb-5{
  padding-bottom: 50px;
}
.container {

  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 600px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 800px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1200px;
  }
}