
.home {
  position: relative;
  width: 100%;
  background: url("../img/bg.jpg") no-repeat fixed;
  background-size: cover;
  height: 100vh;
  z-index: 100;
  .container {
    animation: home-bg 6s linear infinite;
    transition: background .5s ease;

  }
}

@keyframes home-bg {
  0% {
    background: url("../img/bg-group.png") 0 50% no-repeat fixed;
  }
  50% {
    background: url("../img/bg-group.png") 100% 50% no-repeat fixed;
  }

  100% {
    background: url("../img/bg-group.png") 0 50% no-repeat fixed;
  }
}

@keyframes home-arrow {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0px);
  }
}

.img-arrow {
  animation: home-arrow 2s linear infinite;
}

.home-flex {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  h1 {

  }
  .down {
    position: absolute;
    bottom: 0;
    font-family: $main-font;
    font-weight: 900;
    padding-bottom: 40px;
    align-self: center;

    img {
      margin: 0 auto;
      width: 20px;
    }
  }

}

.about {
  p {
    color: rgba(0, 0, 0, .5);
    font-size: 20px;
    max-width: 650px;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

}

.flex-item {
  width: 200px;
  margin-top: 20px;
  flex-basis: 48%;
  position: relative;
  overflow-y: hidden;
  .project-hover {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 60px);
    padding: 10px 30px;
    height: 0;
    background-color: #fff;
    transition: height .5s ease;
    z-index: 100;
    opacity: 0;

    h5 {
      margin: 20px 0;
      line-height: 0;
      font-size: 30px;
      font-family: $main-font;
      font-weight: 900;
    }
    p {
      color: rgba(0, 0, 0, .5);
    }
  }
  &:hover .project-hover {
    opacity: 1;
    z-index: 100;
    height: 100%;
  }
}

.privacy-link {
  text-decoration: none;
  color: #000;
}

.link-project {

  font-size: 18px;
}

.img-link {
  display: inline-block;

  padding-top: 10px;
  width: 15px;
}

.services-container {
  display: flex;
  flex-wrap: wrap;

}

.services-item {
  margin-top: 50px;
  width: 40%;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.img-services {

  max-width: 80px;
  svg {

    width: 100%;
  }
}

.animation-stroke {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.sevices-header {
  text-transform: uppercase;
  font-size: 24px;
  font-family: $main-font;
  font-weight: 900;
  color: rgba(0, 0, 0, .5);
}

.services-list {
  padding: 0;
  margin-left: 15px;
  font-size: 14px;
  color: rgba(0, 0, 0, .5);
  li {
    margin-top: 15px;
  }
}
.address {
  line-height: 1.6;
  font-size: 18px;
  color: #000;
  span{
    display: block;
    padding-top: 10px;
  }
}
.contact-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 60px 0;

}

.social-list {
  display: flex;
  list-style: none;
  padding: 0;

  li {

    width: 40px;
  }
  li:not(:first-of-type) {
    margin-left: 30px;
  }
}

.contact-btn {
  cursor: pointer;
  position: relative;
  letter-spacing: 0.1em;
  font-size: 20px;
  text-transform: uppercase;
  background: transparent;
  border: 0;
  padding: 15px 30px;
  border-bottom: 1px solid #000;
  a {
    color: #000;
    text-decoration: none;
  }
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    background-color: #000;
    z-index: -1;
  }
  &:hover {

    border-bottom: 0;
    a {
      color: #fff;
    }
  }
  &:hover:before {
    height: 100%;
    transition: all .5s ease;
  }
}

.contact-form {
  width: 80%;
  margin: 0 auto;
  margin-top: 200px;
  padding: 40px 60px;
  border-radius: 20px;
  background-color: #E8E8E8;

}

.form-header {
  text-align: center;
  font-family: $main-font;
  font-size: 70px;
}

.form-input {
  width: 100%;
  padding: 10px 0;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #000;
  font-size: 24px;
  outline: 0;
  font-weight: 400;
  &::-webkit-input-placeholder {
    text-align: center;
    color: #000;
  }
  &:focus::-webkit-input-placeholder {
    color: transparent;
    transition: color .3s ease;
  }

}

.form-text {
  display: block;
  padding: 20px;
  margin-top: 40px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #000;
  background-color: transparent;
  &::-webkit-input-placeholder {
    padding: 20px;
    text-align: left;
    color: #000;
  }
  &:focus::-webkit-input-placeholder {
    color: transparent
  }

}

.form-sub-header {
  display: block;
  text-align: center;
  margin-top: 40px;
  font-size: 24px;
}

.form-btn {
  cursor: pointer;
  margin-top: 50px;
  display: block;
  float: right;
  text-transform: uppercase;
  padding: 10px 60px;
  border: 0;
  background-image: $main-gradient;
  color: #fff;
  font-size: 20px;
  &:hover {
    background-color: #000;
    background-image: none;
  }
}

.flex-check {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.text-right {
  text-align: right;
}

.footer-flex {
  width: 100%;
  display: flex;
  justify-content: space-between;

}