.sidebar-wrapper{
  position: fixed;
  width: 120px;
  height: 100vh;
  padding: 10px;
&:before{
  content: "";
  position: absolute;
  left: 120px;
  width: 2px;
  height: 100%;
  background-color: rgba(0, 0, 0, .2);
  z-index: 2;

}
}
.logo{
  max-width: 100px;
}
.menu-logo{
max-width: 100px;
}
.progress{
position: absolute;
  top: 0;
  left: 120px;
  width: 2px;
  height: 0;
  background-color: #000;
  z-index: 2;
  animation: width 10ms ease-in;
}
.sidebar-list{
  display: flex;
  height: 80%;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  list-style: none;

  li{
    margin-top: 100px;
    font-size: 16px;
    font-family: $main-font;
    transform: rotate(-90deg);
    a{
      text-transform: uppercase;
      text-decoration: none;
      color: rgba(0, 0, 0, .5);
    }

  }
}
.active{
  color: rgba(0, 0, 0, 1) !important;
}





$body-background: #F3F3F3;

.top-menu{

  position: fixed;
  top:0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.flex-header{
  display: flex;
  padding: 10px 40px 0 20px;
  justify-content: space-between;
}

.outer-menu {
  position: relative;
  margin-right: 40px;
  margin-top: -10px;
  z-index: 100;
}
.outer-menu .checkbox-toggle {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
  width: 60px;
  height: 60px;
  opacity: 0;
}
.outer-menu .checkbox-toggle:checked + .hamburger > div {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  background-color: #000 !important;

}
.outer-menu .checkbox-toggle:checked + .hamburger > div:before,
.outer-menu .checkbox-toggle:checked + .hamburger > div:after {
  width: 100%;
  top: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: #000;

}
.outer-menu .checkbox-toggle:checked + .hamburger > div:after {
  opacity: 0;
}
.outer-menu .checkbox-toggle:checked ~ .menu {
  pointer-events: auto;
  visibility: visible;
}
.outer-menu .checkbox-toggle:checked ~ .menu > div {
  -webkit-transform: scale(1);
  transform: scale(1);
  transition-duration: 0.75s;
  background-color: #000 !important;
}
.outer-menu .checkbox-toggle:checked ~ .menu > div > div {
  opacity: 1;
  transition: opacity 0.4s ease 0.4s;
  background-color: #000 !important;
}

.outer-menu .checkbox-toggle:checked:hover + .hamburger > div {
  background-color: #000 !important;
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}
.outer-menu .hamburger {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 50px;
  height: 50px;
  padding: 0.5em 1em;
  background: transparent;
  border-radius: 0 0.12em 0.12em 0;
  cursor: pointer;
  transition: box-shadow 0.4s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.outer-menu .hamburger > div {
  position: relative;
  flex: none;
  width: 80%;
  height: 2px;
  background: $body-background;
  transition: all 0.4s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-nav-scroll{
  background-image: $main-gradient !important;
}
.outer-menu .hamburger > div:before,
.outer-menu .hamburger > div:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -10px;
  right: 0;
  width: 120%;
  height: 2px;
  background: inherit;
  transition: all 0.4s ease;
}
.outer-menu .hamburger > div:after {
  top: 10px;
}
.outer-menu .menu {

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  visibility: hidden;
  background-color: #fff;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}


.outer-menu .menu  > ul {
  list-style: none;
  padding: 0 1em;
  margin: 0;
  display: block;
  max-height: 100vh;
}
.outer-menu .menu > ul > li {
  padding: 0;
  margin: 1em;
  font-size: 24px;
  display: block;
}
.outer-menu .menu > ul > li > a {
  font-family: $main-font;
  text-transform: uppercase;
  font-size: 100px;
  font-weight: 900;
  position: relative;
  display: inline;
  cursor: pointer;
  text-decoration: none;
  color: #000;
  transition: color 0.4s ease;
}

.outer-menu .menu >  ul > li > a:hover {
  background: $main-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media only screen and (max-width: 600px) {

  .outer-menu .menu > div > div > ul > li > a {
    font-size: 16px;
    font-weight: 700;
    position: relative;
    display: inline;
    cursor: pointer;
    color: $body-background;
    transition: color 0.4s ease;
  }

}