/* Customize the label (the container) */


.container-checkbox {
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox  input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
display: block;
  position: absolute;

  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background: #E2E2E2;
  border: 1px solid #000000;
}
.text-check{

  margin-left: 10px;
}




/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-checkbox  input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-checkbox  .checkmark:after {
  left: 8px;
  top: 7px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-image: linear-gradient(180deg, #FF096C 0%, #FD702B 100%);

}